<template>
    <default-template
        :title="'温熱・省エネ設備機器ポータルサイト'"
        :is-visible-public="false"
        :is-visible-logout="true"
        :is-visible-pass-update="true"
        :is-visible-jigyosya-login="false"
        :is-house="true"
        :is-visible-administrator-header="true"
    >
        <div class="bl_contents_wrapper">
            <div class="bl_underHeader ly_underHeader">
                <h2 class="el_lv2Heading">評価機関一覧</h2>
                <app-button
                    name="新規作成"
                    @click="createEvaluationOrganization()"
                    ref="jest-evaluation-organization-create"
                    class="inline"
                />
            </div>
            <error-list class="notice" />
            <table
                v-if="evaluationOrganizations.length !== 0"
                key="resultTable"
                ref="jest-result-table"
                class="lists_table"
            >
                <thead>
                    <tr>
                        <th>機関名</th>
                        <th>評価機関番号</th>
                        <th>パスワード</th>
                        <th></th>
                    </tr>
                </thead>
                <tr
                    v-for="item in evaluationOrganizations"
                    :key="'evaluationOrganizations' + item.id"
                    :ref="'jest-evaluation-organization' + item.id"
                >
                    <td>{{item.name}}</td>
                    <td>{{item.number}}</td>
                    <td>{{item.password}}</td>
                    <td>
                        <app-button
                            name="変更"
                            @click="updateEvaluationOrganization(item)"
                            :ref="'jest-evaluation-organization-update' + item.id"
                            class="inline wd_80px"
                        />
                        <app-button
                            name="削除"
                            class="delete inline wd_80px"
                            @click="deleteEvaluationOrganization(item)"
                            :ref="'jest-evaluation-organization-delete' + item.id"
                        />
                    </td>
                </tr>
            </table>
            <!--
            <p
                v-if="evaluationOrganizations.length === 0"
                key="resultParagraph"
                ref="jest-result-paragraph"
            >
                評価機関がありません
            </p>
            -->
        </div>
    </default-template>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { move } from '@/util'
import ErrorList from '@/components/molecules/ErrorList'

export default {
    name: 'AdminEvaluationOrganizationList',
    components:{
        ErrorList,
    },
    computed:{
        ...mapGetters({
            evaluationOrganizations:'admin/evaluationOrganizations/evaluationOrganizations',
        }),
    },
    async mounted(){
        const loader = this.$loading.show()
        await this.getEvaluationOrganizations()
        loader.hide()
    },
    methods:{
        ...mapActions({
            getEvaluationOrganizations:'admin/evaluationOrganizations/getEvaluationOrganizations',
            update:'admin/evaluationOrganizations/update',
            updateEvaluator:'admin/evaluationOrganizations/updateEvaluationOrganization',
            clearError:'error/clear',
        }),
        moveTo(path){
            move(this.$router, path)
        },
        async createEvaluationOrganization(){
            this.clearError()
            await this.update({evaluationOrganization:{
                name:null,
                number:null,
                password:null,
                password_confirmation:null,
            }})
            this.moveTo('/admin/evaluation_organizations/create')
        },
        async updateEvaluationOrganization(item){
            this.clearError()
            await this.update({evaluationOrganization:item})
            await this.updateEvaluator({password_confirmation:item.password})
            this.moveTo('/admin/evaluation_organizations/' + item.id + '/edit')
        },
        async deleteEvaluationOrganization(item){
            this.clearError()
            await this.update({evaluationOrganization:item})
            this.moveTo('/admin/evaluation_organizations/' + item.id + '/delete')
        },
    }
}
</script>

<style scoped>
.bl_contents_wrapper{
    min-width: 800px;
    max-width: 800px;
}
.bl_underHeader {
    min-width: 500px;
}
.lists_table{
    width: 100%;
}

.lists_table tr td:last-of-type{
    text-align: right;
}
.el_button.inline.wd_80px:not(:last-child){
    margin-right: 8px;
}
</style>