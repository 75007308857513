<template>
    <default-template
        :title="'温熱・省エネ設備機器ポータルサイト'"
        :is-visible-public="false"
        :is-visible-logout="true"
        :is-visible-pass-update="true"
        :is-visible-jigyosya-login="false"
        :is-house="true"
        :is-visible-administrator-header="true"
    >
        <div class="bl_contents_wrapper">
            <div class="bl_underHeader ly_underHeader">
                <h2 class="el_lv2Heading">評価機関{{typeName}}</h2>
                <hr class="hp_flexspacer"/>
            </div>
            <error-list />
            <div class="ly_form_box">
                <app-text
                    name="name"
                    v-model="name"
                    label="評価機関名"
                    class="formrow"
                    ref="jest-evaluation-organization-name"
                />
                <app-text
                    name="number"
                    v-model="number"
                    label="評価機関番号"
                    class="formrow"
                    ref="jest-evaluation-organization-number"
                />
                <app-text
                    name="password"
                    v-model="password"
                    label="パスワード"
                    class="formrow"
                    type="password"
                    ref="jest-evaluation-organization-password"
                />
                <app-text
                    name="password_confirmation"
                    v-model="password_confirmation"
                    label="パスワード（確認）"
                    class="formrow"
                    type="password"
                    ref="jest-evaluation-organization-password-confirm-confirmation"
                />
            </div>
            <div class="ly_buttonBox">
                <app-button
                    name="キャンセル"
                    @click="moveTo('/admin/evaluation_organizations')"
                    ref="jest-evaluation-organization-cancel"
                    class="cancel"
                />
                <app-button
                    name="変更"
                    v-if="isEdit"
                    @click="edit"
                    ref="jest-evaluation-organization-edit"
                />
                <app-button
                    name="新規登録"
                    v-if="isCreate"
                    @click="create"
                    ref="jest-evaluation-organization-create"
                />
            </div>
        </div>
    </default-template>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { move, mapComputedProperties } from '@/util'
import ErrorList from '@/components/molecules/ErrorList'

export default {
    name: 'AdminEvaluationOrganizationEdit',
    components:{
        ErrorList,
    },
    computed:{
        ...mapGetters({
            data:'admin/evaluationOrganizations/evaluationOrganization',
        }),
        ...mapComputedProperties([
            'name',
            'number',
            'password',
            'password_confirmation'
        ]),
        typeName(){
            return {
                edit:'変更',
                create:'作成'
            }[this.$route.meta.type]
        },
        isEdit(){
            return this.$route.meta.type === 'edit'
        },
        isCreate(){
            return this.$route.meta.type === 'create'
        },
    },
    methods:{
        ...mapActions({
            update:'admin/evaluationOrganizations/updateEvaluationOrganization',
            createEvaluationOrganization:'admin/evaluationOrganizations/create',
            editEvaluationOrganization:'admin/evaluationOrganizations/edit',
            clearError:'error/clear',
            updateError:'error/update',
        }),
        moveTo(path){
            move(this.$router, path)
        },
        create(){
            this.clearError()
            return this.createEvaluationOrganization()
                .then(response => {
                    if (response.status == 200) {
                        this.updateError(response)
                        this.moveTo('/admin/evaluation_organizations')
                    } else {
                        this.updateError(response)
                    }
                })
        },
        edit(){
            this.clearError()
            return this.editEvaluationOrganization()
                .then(response => {
                    if (response.status == 200) {
                        this.updateError(response)
                        this.moveTo('/admin/evaluation_organizations')
                    } else {
                        this.updateError(response)
                    }
                })
        }
    }
}
</script>

<style scoped>
.bl_contents_wrapper{
    min-width: 800px;
    max-width: 800px;
}
.bl_underHeader {
    width: 400px;
}
.ly_form_box{
    width: 100%;
}
.ly_form_box .formrow{
    width: 100%;
    grid-template-columns: 10em 1fr;
}
</style>