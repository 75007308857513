<template>
    <default-template
        :title="'温熱・省エネ設備機器ポータルサイト'"
        :is-visible-public="false"
        :is-visible-logout="true"
        :is-visible-pass-update="true"
        :is-visible-jigyosya-login="false"
        :is-house="true"
        :is-visible-administrator-header="true"
    >
        <div class="bl_contents_wrapper">
            <div class="bl_underHeader ly_underHeader">
                <h2 class="el_lv2Heading">評価機関削除</h2>
                <hr class="hp_flexspacer"/>
                <p>以下の評価機関を削除します。よろしいですか？</p>
            </div>
            <error-list />
            <div class="ly_form_box">
                <div class="formrow">
                    <app-label
                        name="name"
                        label="評価機関名"
                    />
                    <p ref="jest-user-name">{{data.name}}</p>
                </div>
                <div class="formrow">
                    <app-label
                        name="number"
                        label="評価機関番号"
                    />
                    <p ref="jest-user-number">{{data.number}}</p>
                </div>
                <div class="formrow">
                    <app-label
                        name="password"
                        label="パスワード"
                    />
                    <p ref="jest-user-password">{{data.password}}</p>
                </div>
            </div>
            <div class="ly_buttonBox">
                <app-button
                    name="キャンセル"
                    @click="moveTo('/admin/evaluation_organizations')"
                    ref="jest-evaluation-organization-cancel"
                    class="cancel"
                />
                <app-button
                    name="削除"
                    @click="deleteEvaluationOrganization"
                    ref="jest-evaluation-organization-delete"
                    class="delete"
                />
            </div>
        </div>
    </default-template>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { move } from '@/util'
import ErrorList from '@/components/molecules/ErrorList'

export default {
    name: 'AdminEvaluationOrganizationEdit',
    components:{
        ErrorList,
    },
    computed:{
        ...mapGetters({
            data:'admin/evaluationOrganizations/evaluationOrganization',
        }),
    },
    methods:{
        ...mapActions({
            clearError:'error/clear',
            updateError:'error/update',
            delete:'admin/evaluationOrganizations/delete'
        }),
        moveTo(path){
            move(this.$router, path)
        },
        deleteEvaluationOrganization(){
            this.clearError()
            return this.delete()
                .then(response => {
                    if (response.status == 200) {
                        this.updateError(response)
                        this.moveTo('/admin/evaluation_organizations')
                    } else {
                        this.updateError(response)
                    }
                })
        }
    }
}
</script>

<style scoped>
.bl_contents_wrapper{
    min-width: 800px;
    max-width: 800px;
}
.ly_form_box{
    width: 100%;
}
.ly_form_box .formrow{
    grid-template-columns: 9em 1fr;
    width: 100%;
}
</style>